export const DEFAULT_LIST_SIZE = 30;
export const DEFAULT_SCROLL_OFFSET = 30;

export const DEFAULT_DESKTOP_SIZE = 1580;

export const DEFAULT_PROPS_TABLE = {
  bordered: true,
  pagination: false,
};

export const LANG_MENU = [
  { key: 'en', text: 'English' },
  { key: 'de', text: 'Deutsch' },
];

export const SETTINGS_MENU = [{ key: 'logout', text: 'Log out' }];

export const ADMIN_ROLES = [
  'ROLE_ADMIN',
  'ROLE_S_PATIENT_ADMIN',
  'ROLE_EXAM_ADMIN',
  'ROLE_ROLES_ADMIN',
  'ROLE_OSCE_ADMIN',
  'ROLE_SUPER_ADMIN',
];

export const ADMIN = 'ROLE_ADMIN';
export const S_PATIENT_ADMIN = 'ROLE_S_PATIENT_ADMIN';
export const EXAM_ADMIN = 'ROLE_EXAM_ADMIN';
export const ROLES_ADMIN = 'ROLE_ROLES_ADMIN';
export const OSCE_ADMIN = 'ROLE_OSCE_ADMIN';
export const SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

export const HEADER_MENU = [
  {
    url: '/administration',
    name: 'administration',
    pattern: '/administration',
    access: ADMIN,
  },
  {
    url: '/sp/patients',
    name: 'simulatedPatients',
    pattern: '/sp/',
    access: S_PATIENT_ADMIN,
  },
  {
    url: '/examiners/list',
    name: 'examiners',
    pattern: '/examiners/',
    access: EXAM_ADMIN,
  },
  {
    url: '/roles/definition',
    name: 'roles',
    pattern: '/roles/',
    access: ROLES_ADMIN,
  },
  {
    url: '/osce-administration',
    name: 'OSCEAdministration',
    pattern: '/osce-administration/',
    access: OSCE_ADMIN,
  },
  {
    url: '/system-administration',
    name: 'systemAdministration',
    pattern: '/system-administration',
    access: SUPER_ADMIN,
  },
];

export const STATION_TYPES = {
  REGULAR: 'REGULAR',
  BREAK: 'BREAK',
  PREPARATION: 'PREPARATION',
  DOUBLE: 'DOUBLE',
  DOUBLESP: 'DOUBLESP',
  SAMPLE: 'SAMPLE',
  SP_PAUSED: 'SP_PAUSED',
  PAUSED_STUDENT: 'PAUSED_STUDENT',
};

export const OSCE_STATUS = {
  OSCE_CREATED: 'OSCE CREATED',
  CIRCUITS_CREATED: 'CIRCUITS CREATED',
  SCHEDULE_CREATED: 'SCHEDULE CREATED',
};

export const IMPORT_STATUS = {
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  PARTIALLY_FAILED: 'partially failed',
  NONE: 'none',
};

export const BREAK_TYPES = ['ROTATION_BREAK', 'LUNCH_BREAK', 'COFFEE_BREAK'];
